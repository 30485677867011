//// syncMiddleware.js
//import { updateDataInFirebase } from './firebaseUtils'; // Define your Firebase-related functions
import {initializeApp} from '@firebase/app';

import { getFirestore, collection, getDocs,addDoc,updateDoc,deleteDoc,doc } from '@firebase/firestore/lite';
import deepParseJson from 'utils/deepParseJson';
import app from 'views/gaze/GazeComponents/firebase';
import { PERSIST_STORE_NAME } from 'constants/app.constant'







// const firebaseConfig = {
//     apiKey: "AIzaSyApHx8yJkw-QiIUKNSpHtgNSLc9nYWAdD8",
//     authDomain: "gazeapp-1e7cb.firebaseapp.com",
//     databaseURL: "https://gazeapp-1e7cb-default-rtdb.asia-southeast1.firebasedatabase.app",
//     projectId: "gazeapp-1e7cb",
//     storageBucket: "gazeapp-1e7cb.appspot.com",
//     messagingSenderId: "828369257984",
//     appId: "1:828369257984:web:04916f25580c9f25ff3ae5",
//     measurementId: "G-HK7FB4YY3K"
//   };
  
 // const app =initializeApp(firebaseConfig);
//   const db = getFirestore(app);

//   const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
//   // console.log('persistData12465727656356735675', rawPersistData);
//    const persistData = deepParseJson(rawPersistData)
   
//   // let messageId = persistData.auth.session.messageId
//   // console.log("Inside messageId"+messageId)
//   //const messgaeId= messageId?messageId:"uzl2dWzZzsZBcByPbvfW";
//  // const messageId= persistData?persistData.auth.session.messageId:"flPh56vvPOia5K8wvLbd";

 // const messageId= "flPh56vvPOia5K8wvLbd";
   const firestore = getFirestore(app);
  //  const usersCollection = collection(firestore, 'messages');
  //   const documentRef = doc(usersCollection,  messageId);

// Middleware that syncs data to Firebase after the Redux store updates
const synchMiddleware = (store) => (next) => (action) => {
  // Call the next middleware
 const result = next(action);
//  const firestore = getFirestore();
//  const usersCollection = collection(firestore, 'messages');




//   if(action.type === 'tutor/getChatGPTApiData/fulfilled'){

//  //  console.log('Document added with ID: '+store.getState().liveCodeSlice.documentId);
//    const messageCollection = collection(firestore, 'sharedMessages');
//    let documentRef="";
//    let documentRefProjectId=""

//    if(store.getState().liveCodeSlice.documentId){
//      documentRef = doc(messageCollection, store.getState().liveCodeSlice.documentId);
//    }

//    const projectId=store.getState().liveCodeSlice.projectList[store.getState().liveCodeSlice.courseId].id.split('-')[1]
//    if(projectId){
//     documentRefProjectId = doc(messageCollection, projectId);
//   }
  

//   try{
//   //  console.log('Document added with ID:= '+deepParseJson(JSON.stringify(store.getState().liveCodeSlice)));
//     const stateToPersist = deepParseJson(JSON.stringify(store.getState().liveCodeSlice.projectList[store.getState().liveCodeSlice.courseId]));
    
//     if(documentRef && stateToPersist )
//     {
//       updateDoc(documentRefProjectId,stateToPersist);
//     }
   
     
//  //   console.log('Document successfully updated!');
//   } catch (error) {
//     console.error('Error updating document:', error);
//   }
//   }


  if(action.type === 'liveProject/getAssesmemnt/fulfilled'){

    //  console.log('Document added with ID: '+store.getState().liveCodeSlice.documentId);
      const messageCollection = collection(firestore, 'sharedMessages');
      let documentRef="";
      let documentRefProjectId=""
   
      if(store.getState().liveCodeSlice.documentId){
        documentRef = doc(messageCollection, store.getState().liveCodeSlice.documentId);
      }
   
      const projectId=store.getState().liveCodeSlice.projectList[store.getState().liveCodeSlice.courseId].id.split('-')[1]
      if(projectId){
       documentRefProjectId = doc(messageCollection, projectId);
     }
     
   
     try{
     //  console.log('Document added with ID:= '+deepParseJson(JSON.stringify(store.getState().liveCodeSlice)));
       const stateToPersist = deepParseJson(JSON.stringify(store.getState().liveCodeSlice.projectList[store.getState().liveCodeSlice.courseId]));
       
       if(documentRef && stateToPersist && stateToPersist!=="")
       {
         updateDoc(documentRefProjectId,stateToPersist);
       }
      
        
    //   console.log('Document successfully updated!');
     } catch (error) {
       console.error('Error updating document:', error);
     }
     }

  if(action.type === 'liveProject/createUserInternal/fulfilled'){

    //  console.log('Document added with ID: '+store.getState().liveCodeSlice.documentId);
      const messageCollection = collection(firestore, 'messages');
      let documentRef="";
   
      if(store.getState().liveCodeSlice.documentId){
        documentRef = doc(messageCollection, store.getState().liveCodeSlice.documentId);
      }
     
   
     try{
     //  console.log('Document added with ID:= '+deepParseJson(JSON.stringify(store.getState().liveCodeSlice)));
       const stateToPersist = deepParseJson(JSON.stringify(store.getState().liveCodeSlice));
       
       if(documentRef && stateToPersist && stateToPersist!=="")
       {
         updateDoc(documentRef,stateToPersist);
       }
      
        
    //   console.log('Document successfully updated!');
     } catch (error) {
       console.error('Error updating document:', error);
     }
     }

    //  if(action.type === 'liveProject/getAssesmemnt/fulfilled'){

    //   //  console.log('Document added with ID: '+store.getState().liveCodeSlice.documentId);
    //     const messageCollection = collection(firestore, 'messages');
    //     let documentRef="";
     
    //     if(store.getState().liveCodeSlice.documentId){
    //       documentRef = doc(messageCollection, store.getState().liveCodeSlice.documentId);
    //     }
       
     
    //    try{
    //    //  console.log('Document added with ID:= '+deepParseJson(JSON.stringify(store.getState().liveCodeSlice)));
    //      const stateToPersist = deepParseJson(JSON.stringify(store.getState().liveCodeSlice));
         
    //      if(documentRef && stateToPersist && stateToPersist!=="")
    //      {
    //        updateDoc(documentRef,stateToPersist);
    //      }
        
          
    //   //   console.log('Document successfully updated!');
    //    } catch (error) {
    //      console.error('Error updating document:', error);
    //    }
    //    }

    

    if(action.type === 'liveProject/getChatGPTApiEvaluateData/fulfilled'){

      //  console.log('Document added with ID: '+store.getState().liveCodeSlice.documentId);
        const messageCollection = collection(firestore, 'sharedMessages');
        let documentRef="";
     
        // if(store.getState().liveCodeSlice.documentId){
        //   documentRef = doc(messageCollection, store.getState().liveCodeSlice.documentId);
        // }
       
     
       try{
       //  console.log('Document added with ID:= '+deepParseJson(JSON.stringify(store.getState().liveCodeSlice)));
       const projectData=store.getState().liveCodeSlice.projectList[store.getState().liveCodeSlice.courseId]
       const idToUpdate = projectData.id.split('-')[1];
       console.log('Document added with ID: '+idToUpdate);
       documentRef = doc(messageCollection, idToUpdate);
       //  const stateToPersist = deepParseJson(JSON.stringify());
         
         if(documentRef && projectData )
         {
           updateDoc(documentRef,projectData);
         }
        
          
      //   console.log('Document successfully updated!');
       } catch (error) {
         console.error('Error updating document:', error);
       }
       }

       if(action.type === 'liveProject/getRelatedQuestionAnswers/fulfilled'){

        //  console.log('Document added with ID: '+store.getState().liveCodeSlice.documentId);
          const messageCollection = collection(firestore, 'sharedMessages');
          let documentRef="";
       
          // if(store.getState().liveCodeSlice.documentId){
          //   documentRef = doc(messageCollection, store.getState().liveCodeSlice.documentId);
          // }
         
       
         try{
         //  console.log('Document added with ID:= '+deepParseJson(JSON.stringify(store.getState().liveCodeSlice)));
         const projectData=store.getState().liveCodeSlice.projectList[store.getState().liveCodeSlice.courseId]
         const idToUpdate = projectData.id.split('-')[1];
         console.log('Document added with ID: '+idToUpdate);
         documentRef = doc(messageCollection, idToUpdate);
          const stateToPersist = deepParseJson(JSON.stringify(projectData));
           
           if(documentRef && projectData )
           {

            console.log('Inside updating document:', stateToPersist);
             updateDoc(documentRef,stateToPersist);
           }
          
            
        //   console.log('Document successfully updated!');
         } catch (error) {
           console.error('Error updating document:', error);
         }
         }

       
       if(action.type === 'liveCodeSlice/state/setCompletedQuestions'){

        //  console.log('Document added with ID: '+store.getState().liveCodeSlice.documentId);
          const messageCollection = collection(firestore, 'sharedMessages');
          let documentRef="";
       
          // if(store.getState().liveCodeSlice.documentId){
          //   documentRef = doc(messageCollection, store.getState().liveCodeSlice.documentId);
          // }
         
       
         try{
         //  console.log('Document added with ID:= '+deepParseJson(JSON.stringify(store.getState().liveCodeSlice)));
         const projectData=store.getState().liveCodeSlice.projectList[store.getState().liveCodeSlice.courseId]
         const idToUpdate = projectData.id.split('-')[1];
         console.log('Document added with ID: '+idToUpdate);
         documentRef = doc(messageCollection, idToUpdate);
          const stateToPersist = deepParseJson(JSON.stringify(projectData));
           
           if(documentRef && projectData )
           {

            console.log('Inside updating document:', stateToPersist);
             updateDoc(documentRef,stateToPersist);
           }
          
            
        //   console.log('Document successfully updated!');
         } catch (error) {
           console.error('Error updating document:', error);
         }
         }
         
         if(action.type === 'liveProject/getAssesmemntInCourse/fulfilled'){

          //  console.log('Document added with ID: '+store.getState().liveCodeSlice.documentId);
            const messageCollection = collection(firestore, 'sharedMessages');
            let documentRef="";
         
            // if(store.getState().liveCodeSlice.documentId){
            //   documentRef = doc(messageCollection, store.getState().liveCodeSlice.documentId);
            // }
           
         
           try{
           //  console.log('Document added with ID:= '+deepParseJson(JSON.stringify(store.getState().liveCodeSlice)));
           const projectData=store.getState().liveCodeSlice.projectList[store.getState().liveCodeSlice.courseId]
           const idToUpdate = projectData.id.split('-')[1];
           console.log('Document added with ID: '+idToUpdate);
           documentRef = doc(messageCollection, idToUpdate);
            const stateToPersist = deepParseJson(JSON.stringify(projectData));
             
             if(documentRef && projectData )
             {
  
            //  console.log('Inside updating document:', stateToPersist);
               updateDoc(documentRef,stateToPersist);
             }
            
              
          //   console.log('Document successfully updated!');
           } catch (error) {
             console.error('Error updating document:', error);
           }
           }

           if(action.type === 'liveCodeSlice/state/setAnswerGivenByUser'){

            //  console.log('Document added with ID: '+store.getState().liveCodeSlice.documentId);
              const messageCollection = collection(firestore, 'sharedMessages');
              let documentRef="";
           
              // if(store.getState().liveCodeSlice.documentId){
              //   documentRef = doc(messageCollection, store.getState().liveCodeSlice.documentId);
              // }
             
           
             try{
             //  console.log('Document added with ID:= '+deepParseJson(JSON.stringify(store.getState().liveCodeSlice)));
             const projectData=store.getState().liveCodeSlice.projectList[store.getState().liveCodeSlice.courseId]
             const idToUpdate = projectData.id.split('-')[1];
             console.log('Document added with ID: '+idToUpdate);
             documentRef = doc(messageCollection, idToUpdate);
              const stateToPersist = deepParseJson(JSON.stringify(projectData));
               
               if(documentRef && projectData )
               {
    
              //  console.log('Inside updating document:', stateToPersist);
                 updateDoc(documentRef,stateToPersist);
               }
              
                
            //   console.log('Document successfully updated!');
             } catch (error) {
               console.error('Error updating document:', error);
             }
             }


           if(action.type === 'tutor/getChatGPTApiData/fulfilled'){

            //  console.log('Document added with ID: '+store.getState().liveCodeSlice.documentId);
              const messageCollection = collection(firestore, 'sharedMessages');
              let documentRef="";
           
              // if(store.getState().liveCodeSlice.documentId){
              //   documentRef = doc(messageCollection, store.getState().liveCodeSlice.documentId);
              // }
             
           
             try{
             //  console.log('Document added with ID:= '+deepParseJson(JSON.stringify(store.getState().liveCodeSlice)));
             const projectData=store.getState().liveCodeSlice.projectList[store.getState().liveCodeSlice.courseId]
             const idToUpdate = projectData.id.split('-')[1];
             console.log('Document added with ID: '+idToUpdate);
             documentRef = doc(messageCollection, idToUpdate);
              const stateToPersist = deepParseJson(JSON.stringify(projectData));
               
               if(documentRef && projectData )
               {
    
            //  console.log('Inside updating document:', stateToPersist);
                 updateDoc(documentRef,stateToPersist);
               }
              
                
              console.log('Document successfully updated!');
             } catch (error) {
               console.error('Error updating document:', error);
             }
             }     

       if(action.type === 'liveCodeSlice/state/fetchSelefStudyTest'){

        //  console.log('Document added with ID: '+store.getState().liveCodeSlice.documentId);
          const messageCollection = collection(firestore, 'messages');
          let documentRef="";
       
          if(store.getState().liveCodeSlice.documentId){
            documentRef = doc(messageCollection, store.getState().liveCodeSlice.documentId);
          }
         
       
         try{
         //  console.log('Document added with ID:= '+deepParseJson(JSON.stringify(store.getState().liveCodeSlice)));
           const stateToPersist = deepParseJson(JSON.stringify(store.getState().liveCodeSlice));
           
           if(documentRef && stateToPersist && stateToPersist!=="")
           {
             updateDoc(documentRef,stateToPersist);
           }
          
            
        //   console.log('Document successfully updated!');
         } catch (error) {
           console.error('Error updating document:', error);
         }
         }

  if(action.type === 'liveProject/sendSharedCourseUsers/fulfilled'){

    //  console.log('Document added with ID: '+store.getState().liveCodeSlice.documentId);
      const messageCollection = collection(firestore, 'messages');
      let documentRef="";
   
      if(store.getState().liveCodeSlice.documentId){
        documentRef = doc(messageCollection, store.getState().liveCodeSlice.documentId);
      }
     
   
     try{
     //  console.log('Document added with ID:= '+deepParseJson(JSON.stringify(store.getState().liveCodeSlice)));
       const stateToPersist = deepParseJson(JSON.stringify(store.getState().liveCodeSlice));
       
       if(documentRef && stateToPersist && stateToPersist!=="")
       {
         updateDoc(documentRef,stateToPersist);
       }
      
        
    //   console.log('Document successfully updated!');
     } catch (error) {
       console.error('Error updating document:', error);
     }
     }

  


  if(action.type === 'liveCodeSlice/state/setScoreForTopic'){

  //  console.log('Document added with ID: '+store.getState().liveCodeSlice.documentId);
    const messageCollection = collection(firestore, 'messages');
    const documentRef = doc(messageCollection, store.getState().liveCodeSlice.documentId);
 
   try{
  //   console.log('Document added with ID:= '+deepParseJson(JSON.stringify(store.getState().liveCodeSlice)));
     const stateToPersist = deepParseJson(JSON.stringify(store.getState().liveCodeSlice));
     
     if(stateToPersist && stateToPersist!=="")
     {
       updateDoc(documentRef,stateToPersist);
     }
    
      
  //   console.log('Document successfully updated!');
   } catch (error) {
     console.error('Error updating document:', error);
   }
   }


   if(action.type === 'liveCodeSlice/state/setRowBack'){

  //  console.log('Document added with ID: '+store.getState().liveCodeSlice.documentId);
    const messageCollection = collection(firestore, 'messages');
    const documentRef = doc(messageCollection, store.getState().liveCodeSlice.documentId);
 
   try{
    // console.log('Document added with ID:= '+deepParseJson(JSON.stringify(store.getState().liveCodeSlice)));
     const stateToPersist = deepParseJson(JSON.stringify(store.getState().liveCodeSlice));
     
     if(stateToPersist && stateToPersist!=="")
     {
       updateDoc(documentRef,stateToPersist);
     }
    
      
     console.log('Document successfully updated!');
   } catch (error) {
     console.error('Error updating document:', error);
   }
   }













  // try{
  //   console.log('Document added with ID: ');

    
  //   const documentRef = doc(usersCollection, 'uzl2dWzZzsZBcByPbvfW');
   
  //    updateDoc(documentRef, store.getState().liveCodeSlice);
  //   console.log('Document successfully updated!');
  // } catch (error) {
  //   console.error('Error updating document:', error);
  // }

    // const currentObject =store.getState().liveCodeSlice.newHistory[0]
    // const previousObject = store.getState().liveCodeSlice.previousNewHistory[0]; // Assuming you store the previous object in your state

    // Check if both objects exist
    // if (currentObject && previousObject) {
    //     // Convert the objects to JSON strings for comparison
    //     const currentObjectString = JSON.stringify(currentObject);
    //     const previousObjectString = JSON.stringify(previousObject);

    //     // Compare the JSON strings
    //     if (currentObjectString === previousObjectString) {
    //         // The objects are different
    //         try{
    //           console.log('Document added with ID: ');

              
    //           const documentRef = doc(usersCollection, 'uzl2dWzZzsZBcByPbvfW');
             
    //            updateDoc(documentRef, store.getState().liveCodeSlice);
    //           console.log('Document successfully updated!');
    //         } catch (error) {
    //           console.error('Error updating document:', error);
    //         }
            
    //     }
    // }





// //  const db = getFirestore(app);
// //         const usersCollection = collection(db, 'messages');
// //         addDoc(usersCollection, store.getState().liveCodeSlice.messages)
// //         .then((docRef) => {
// //           console.log('Document added with ID: ', docRef.id);
// //         })
// //         .catch((error) => {
// //           console.error('Error adding document: ', error);
// //         });


//   }


// if(action.type === 'projectList/data/getList'){

 

//   const docRef = collection(db, 'messages');
//   console.log('Existing data:', docRef);
//    getDocs(docRef)
//   .then((docSnap) => {
//     if (docSnap) {
//       const existingData = docSnap;
//       existingData.forEach((docSnapshot) => {
//         const data = docSnapshot.data();
//         console.log('Existing data:================', data.createdCourses);
//       //  dataArray.push(data);
//       store.getState().liveCodeSlice=data;
//       });
     
//     } else {
//       console.log('Document does not exist');
//     }
//   })
//   .catch((error) => {
//     console.error('Error getting document: ', error);
//   });


// }



  // Check if the action type is the one you want to sync
//   if (action.type === 'liveProject/getCourseDetailsApiData/fulfilled') {
//     // Get the updated data from the store
//     const updatedData = store.getState().liveCodeSlice;

//   //    const newUserData = {
//   //   name: 'Deepakm   Kumar',
//   //   email: 'johndoe@example.com',
//   //   // ...other fields
//   // };

//     // Call your Firebase-related function to sync data
//     console.log("The styore data====="+JSON.stringify(updatedData))

//     if (updatedData &&   typeof updatedData === 'object') {
//       const usersCollection = collection(db, 'liveCode');

//       // Delete the document
// //       deleteDoc(usersCollection)
// // .then(() => {
// //   console.log("Document successfully deleted!");


// //   addDoc(usersCollection, updatedData)
// //     .then((docRef) => {
// //       console.log('Document added with ID: ', docRef.id);
// //     })
// //     .catch((error) => {
// //       console.error('Error adding document: ', error);
// //     });




// try {
//  // const docRef = collection(db, 'liveCode');

// // const collectionRef = collection(app, 'liveCode');
// //  const docRef = doc(collectionRef, 'ao4MvMcDTCX1z0fDCAPI');
//   //const docRef = doc(app, 'liveCode', 'ao4MvMcDTCX1z0fDCAPI');

//   const usersCollection = collection(firestore, 'liveCode');
//   const documentRef = doc(usersCollection, 'ao4MvMcDTCX1z0fDCAPI');
//    updateDoc(documentRef, updatedData);
//   console.log('Document successfully updated!');
// } catch (error) {
//   console.error('Error updating document:', error);
// }












//     }
  
//   }



  //  updateDataInFirebase(updatedData);
//  }

  // Return the result of the next middleware
  return result;
};

export default synchMiddleware;
