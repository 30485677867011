const appConfig = {
    apiPrefix: '/api',
    authenticatedEntryPath: 'app/dashboard',
   // authenticatedEntryPath: 'app/studentDashboard',
    authenticatedStudentEntryPath: 'app/selfStudyDashboard',
    unAuthenticatedEntryPath: '/sign-in',
    selfStudyDahboard: 'app/selfStudyDashboard',
    paymentPage: 'app/payment-page',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
   gpt_model:'gpt-4o',
 //  gpt_model:'gpt-3.5-turbo',
    gpt_model_python:'gpt-4-1106-preview',
    successUrl:'https://beta.gaze.co.in/app/paySuccess',
    cancelUrl:'https://beta.gaze.co.in/app',
    domainUrl:'https://beta.gaze.co.in',
    price_id: "price_1QQ3IzSGbOxjK1qx7IJNltPZ",
    key_id_rajor:'rzp_live_0rGAWOQWNsVhTF',
   
    apiKey:"pk_live_51O2A2BSGbOxjK1qxQXeNMkDvCZMHgmCDFu91h1rUb6vU2OklxEw33FZeNJ8r0ErTqXistqRPvwopfQkH5sJvm9tZ00N1RuVDCz",
    secretKey:"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",
    preSignedUrl:"https://server.gaze.co.in/generate-presigned-url",

  //  apiKey:"pk_test_51O2A2BSGbOxjK1qxkb8fOsEDIhVxxPtxFPlsmL53VqaM8nF7Q9vi1lBi6xSMctdzCDofeMVpnYTXobhqiadmlcGk00OaOjyR0v",
  //  secretKey:"sk_test_51O2A2BSGbOxjK1qxjR4e1ydJX946zuKuZZjGBrIngEfIp7idcsBfsp0V9AKGEONpTz26ndvmOzn6sIVJJJFJCKlF00Bw46u4rf",

   // apiPrefix: '/api',
    //apiSerevrPrefix: 'https://us-central1-gazeapp-1e7cb.cloudfunctions.net',
   apiSerevrPrefix: 'https://us-central1-gazeapp-1e7cb.cloudfunctions.net',
   //apiNewServer: 'http://127.0.0.1:5001/gazeapp-1e7cb/us-central1',
  apiNewServer:'https://us-central1-gazenew-328a0.cloudfunctions.net',
   // apiNewServer:'http://127.0.0.1:5001/gazenew-328a0/us-central1',
  
   //apiCustomSerevrPrefix: 'http://34.100.174.242:8000',
   apiCustomSerevrPrefix: 'https://us-central1-solar-icon-418811.cloudfunctions.net',
   uploadServer: 'https://server.gaze.co.in',
   youtubeServerPrefix:'https://www.googleapis.com'
    
}

export default appConfig
