// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyApHx8yJkw-QiIUKNSpHtgNSLc9nYWAdD8",
//   authDomain: "gazeapp-1e7cb.firebaseapp.com",
//   projectId: "gazeapp-1e7cb",
//   storageBucket: "gazeapp-1e7cb.appspot.com",
//   messagingSenderId: "828369257984",
//   appId: "1:828369257984:web:04916f25580c9f25ff3ae5",
//   measurementId: "G-HK7FB4YY3K"
// };

// // Initialize Firebase
// const firebaseApp = initializeApp(firebaseConfig);
// const analytics = getAnalytics(firebaseApp);
// export { firebaseApp , analytics};

import {initializeApp} from '@firebase/app';
//import {getFirestore,}, from  'firebase/firestore';
import { getFirestore, collection, getDocs,addDoc } from '@firebase/firestore/lite';
//import { enableMultiTabIndexedDbPersistence } from '@firebase/firestore'; 



// const firebaseConfig = {
//   apiKey: "AIzaSyCpcb7-3bI86_DSOX_cxPtHULK36eK4aVo",
//   authDomain: "web-application-f84a0.firebaseapp.com",
//   projectId: "web-application-f84a0",
//   storageBucket: "web-application-f84a0.appspot.com",
//   messagingSenderId: "183180595039",
//   appId: "1:183180595039:web:d664841fe9083068fdf0ea",
// };

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyBzDaBDBJyI06lD1GYdzM9Uy3gntEDbiPk",
  authDomain: "gazenew-328a0.firebaseapp.com",
  projectId: "gazenew-328a0",
  storageBucket: "gazenew-328a0.appspot.com",
  messagingSenderId: "495323692446",
  appId: "1:495323692446:web:198cd6f2210186369e6a8f",
  measurementId: "G-3LLE8JKM7S"
};

const app =initializeApp(firebaseConfig);
//const firestore = getFirestore(app);

// enableMultiTabIndexedDbPersistence(db)
//   .catch((error) => {
//     if (error.code === 'failed-precondition') {
//       console.log('Multiple tabs open, persistence can only be enabled in one tab at a time.');
//     } else if (error.code === 'unimplemented') {
//       console.log('The current browser does not support all the features required to enable persistence.');
//     }
//   });

 // const dbRef = db.ref();
  //console.log('Data saved successfully444444444444444+'+dbRef.child('.info/connected'));
  // const data = {test:'value1'}



  // const newUserData = {
  //   name: 'DeepakmKumar',
  //   email: 'johndoe@example.com',
  //   // ...other fields
  // };
  
  // // Reference the 'users' collection
  // const usersCollection = collection(db, 'deepaktest1');
  
  // Add a new document with the data to the collection
  // addDoc(usersCollection, newUserData)
  //   .then((docRef) => {
  //     console.log('Document added with ID: ', docRef.id);
  //   })
  //   .catch((error) => {
  //     console.error('Error adding document: ', error);
  //   });












  // Push data to a new child node under 'data'
// db.child('data').push(data, (error) => {
//     if (error) {
//       console.error('Data saved successfully111111112:', error);
//     } else {
//       console.log('Data saved successfully33333333333333');
//     //  setData('');
//     }
//   });


  // async function getCities(db) {
  //   const citiesCol = collection(db, 'users');
  //   const citySnapshot = await getDocs(citiesCol);
  //   const cityList = citySnapshot.docs.map(doc => doc.data());
  //   return cityList;
  // }



//console.log("database connect====+"+JSON.stringify(getCities(db)))

// getFirestore()
//   .catch((error) => {
//     if (error.code === 'failed-precondition') {
//       console.log('Multiple tabs open, persistence can only be enabled in one tab at a time.');
//     } else if (error.code === 'unimplemented') {
//       console.log('The current browser does not support all the features required to enable persistence.');
//     }
//   });

export default app;





///export const auth = fire.database().auth();

//export default firebase;
